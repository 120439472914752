.about-wrapper {
	padding: 2rem;
	overflow: hidden;
}

.about-wrapper h2 {
	text-align: center;
	font-size: 2rem;
	margin: 1rem 0 3rem 0;
	color: var(--global-color-primary);
	position: relative;
	animation: about-h2-appearance 1s ease;
}

.members {
	text-align: center;
}

.members-list {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	/* flex-direction: column; */
	/* flex-wrap: wrap; */
	/* width: max-content; */
	/* height: 200px; */
}

.members-list li {
	margin: 5px;
	color: var(--global-color-primary);
}

.cards-wrapper {
	display: flex;
	justify-content: center;
	gap: 2rem;
	position: relative;
	flex-wrap: wrap;
}

@keyframes about-h2-appearance {
	0% {
		top: -80px;
	}

	100% {
		top: -1px;
	}
}

@media screen and (max-width: 1024px) {
	.members-list {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 700px) {
	.members-list {
		height: unset;
	}
	.members-list {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 500px) {
	.members-list {
		grid-template-columns: 1fr
	}
}
