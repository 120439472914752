.news-detail-page-wrapper {
	max-width: 1600px;
	margin: auto;
	padding: 2rem;
	overflow: hidden;
}

.news-detail-page-wrapper h1 {
	color: var(--global-color-text-blue);
	text-align: center;
	font-size: 1.5rem;
	margin: 1rem;
	font-weight: 700;
	position: relative;
	animation: news-detail-page-wrapper-h1-appearance 3s ease-in-out;
}

.news-detail-page-wrapper p {
	color: var(--global-color-primary);
	max-width: 80%;
	margin: auto;
	line-height: 1.5;
	text-shadow: 1px 1px 1px lightblue;
	position: relative;
	animation: news-detail-page-wrapper-p-appearance 3s ease-in-out;
}

.news-details-main-text {
	line-height: 30px;
	word-spacing: 5px;
}

@media screen and (max-width: 1024px) {
	.news-detail-page-wrapper p {
		max-width: 95%;
	}
}

@keyframes news-detail-page-wrapper-h1-appearance {
	0% {
		left: -100%;
	}
	50% {
		left: 20%;
	}
	100% {
		left: 0%;
	}
}

@keyframes news-detail-page-wrapper-p-appearance {
	0% {
		bottom: -100px;
	}
	50% {
		bottom: 20px;
	}
	100% {
		bottom: 0px;
	}
}
