.admin-panel-wrapper {
	min-height: 600px;
	display: flex;
	position: relative;
}

.admin-nav {
	width: 250px;
	border-right: 2px solid lightgrey;
	padding: 1rem;
}

.admin-nav li {
	margin-bottom: 1.5rem;
	font-size: 1.2rem;
	text-align: center;
}

.admin-nav-button {
	text-decoration: none;
	padding: 0.5rem;
}

.admin-heading {
	text-align: center;
	color: var(--global-color-text-blue);
	font-size: 1.5rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid lightgray;
	padding-bottom: 1rem;
}

.admin-main-section {
	width: 100%;
}

@media screen and (max-width: 1024px) {
	.admin-panel-wrapper {
		flex-direction: column;
	}
	.admin-nav { 
		width: unset;
	}
}