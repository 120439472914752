.home-single-new-wrapper {
	transform: scale(0);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
	width: 17%;
	align-items: center;
	color: var(--global-color-primary);
	box-shadow: 5px 1px 20px lightgray;
	padding: 20px;
	border-radius: 10px;
	margin: 1rem;

	animation: home-single-new-appearance 1.5s forwards;
}

.home-single-new-wrapper h3 {
	max-width: 95%;
	word-wrap: break-word;
	font-size: 1.3rem;
	color: var(--global-color-text-blue);
}

.home-single-new-button {
	width: 70%;
	border: none;
	background-color: var(--global-color-background-secondary-green);
	color: white;
	padding: 10px 20px;
	border-radius: 15px;
	cursor: pointer;
	border: 2px solid var(--global-color-background-secondary-green);
	font-weight: 700;
}

.home-single-new-button:hover {
	background-color: white;
	color: var(--global-color-background-secondary-green);
}

.home-single-new-date {
	position: absolute;
	top: -10px;
	right: 0;
	background-color: var(--global-color-background-secondary-green);
	padding: 5px;
	border-radius: 5px;
}

@media screen and (max-width: 1024px) {
	.home-single-new-wrapper {
		width: 90%;
		max-width: 600px;
		margin: 1rem auto;
	}
}

@keyframes home-single-new-appearance {
	0% {
		transform: scale(0.1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}
