.user-row {
	border-bottom: 1px solid lightgray;
}

.user-delete-button {
	border: none;
	background-color: red;
	color: white;
	padding: 10px 20px;
	border-radius: 15px;
	font-weight: 700;
}

.user-delete-button:hover {
	transform: scale(1.1);
}

@media screen and (max-width: 800px) {
	.id-column {
		display: none;
	}
}
