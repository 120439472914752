.news-wrapper {
	padding: 2rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.news-wrapper table {
	width: 100%;
	border: 2px solid var(--global-color-primary);
	min-height: 200px;
	min-width: 800px;
	padding: 1rem;
}

.news-wrapper table thead {
	padding: 1rem;
}

.news-wrapper table th {
	padding: 1rem;
	text-align: center;
	border-bottom: 1px solid black;
}

.news-wrapper table td {
	padding: 1rem;
	text-align: center;
}

.news-list-no-news {
	text-align: center;
	color: var(--global-color-primary);
	font-size: 1.5rem;
	margin: 2rem 0;
}

.news-tab-wrapper {
	display: flex;
	width: 100%;
	flex-direction: column;
}

.admin-news-pagination {
	margin-bottom: 1rem;
}

.admin-news-loader {
	align-self: center;
	justify-self: center;
}


@media screen and (max-width: 800px) { 
	.news-wrapper table {
		/* display: flex; */
		min-width: unset;
		justify-content: center;
		align-items: center;
	}
	
	.news-wrapper table thead {
		display: none;
	}

	.news-wrapper table tbody{
		overflow-x: auto;
		overflow-y: hidden;

		height: calc((counter(totalheader) * var(--responsive-table-cell-height)) + 0px);
	}

	.news-wrapper th,
	.news-wrapper td {
		/* header on left-side, so important to set same height for all cells */
		display: block;
		height: var(--responsive-table-cell-height);
		margin: 1rem auto;
	}
}