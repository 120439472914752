.edit-new-page-wrapper {
	text-align: center;
	padding: 2rem 1rem;
	display: flex;
	justify-content: center;
}

.edit-new-content-wrapper {
	box-shadow: 1px 1px 20px rgb(82, 80, 80);
	background-color: white;
	width: 60%;
	height: 60%;
	border-radius: 10px;
	padding: 2rem;
}

.edit-new-content-wrapper form label {
	color: var(--global-color-primary);
	font-size: 1.5rem;
}

.edit-new-content-wrapper form {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
}

.edit-new-form-input {
	width: 95%;
	max-width: 900px;
	border: none;
	background-color: rgb(233, 233, 233);
	padding: 0.8rem;
	border-radius: 10px;
}

.edit-new-button-wrapper {
	display: flex;
	align-items: center;
	gap: 2rem;
}

.edit-new-button {
	text-decoration: none;
	color: white;
	padding: 10px 30px;
	border-radius: 10px;
	font-weight: 700;
}

.edit-new-edit-button {
	background-color: orange;
	border: 2px solid orange;
}

.edit-new-deletes-button {
	background-color: red;
	border: 2px solid red;
}

.edit-new-edit-button:hover {
	color: orange;
	background-color: white;
}

.edit-new-deletes-button:hover {
	color: red;
	background-color: white;
}

@media screen and (max-width: 1024px) {
	.edit-new-content-wrapper {
		width: 90%;
	}
}