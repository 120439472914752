.regulations-wrapper  table td {
    padding: 1rem;
    text-align: center;
    vertical-align: middle;
}

.regulation-button {
	border: none;
	color: white;
	padding: 10px 20px;
	border-radius: 15px;
	font-weight: 700;
	margin: 10px;
}

.regulation-button:hover {
	transform: scale(1.1);
}

.regulation-edit {
	background-color: rgb(255, 136, 0);
}

.regulation-delete {
	background-color: red;
}

.regulation-row {
	border-bottom: 1px solid lightgray;
}

.regulation-logo * {
    max-height: 50px;
}

@media screen and (max-width: 1000px) {
	.regulations-wrapper table {
		min-width: unset;
		max-width: 98%;
	}

	.doc-description-row {
		display: none;
	}
	.regulations-wrapper table td {
		padding: unset;
	}
}

@media screen and (max-width: 800px) {
	.regulations-wrapper {
		padding: unset;
	}

	.regulations-wrapper table {
		min-width: unset;
	}
}
