.card-wrapper {
	width: 20%;
	max-width: 250px;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: space-around;
	align-items: center;
	background-color: var(--global-color-background-secondary-green);
	padding: 2rem;
	border-radius: 25px;
	height: 400px;
	color: white;
	font-size: 1.2rem;
	text-align: center;
	position: relative;
	overflow: hidden;
	margin: 1rem 0;
	/* right: -100%; */
	/* animation: card-wrapper-appearance 1.2s forwards; */
}

.card-wrapper img {
	width: 130px;
	height: 130px;
	border-radius: 50%;
	object-fit: cover;
	background-color: white;
}

.visible-doctor-card {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: center;
}

.visible-doctor-card h1 {
	font-size: 1.5rem;
}

.hidden-doctor-card {
	position: absolute;
	height: 100%;
	left: -500px;
	width: 100%;
	transition: all 600ms ease-out;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10%;
	border-radius: 25px;
	background-color: var(--global-color-primary);
}

.hidden-doctor-card h1 {
	font-size: 1.5rem;
}

.card-wrapper:hover .hidden-doctor-card {
	left: 0px;
	background-color: var(--global-color-primary);
}

.hidden-doctor-card-p {
	margin: 0 5px;
}

.doctor-card-button {
	background-color: white;
	border: none;
	width: 80%;
	margin: 0 auto;
	padding: 10px 15px;
	border-radius: 10px;
}

.doctor-card-button:hover {
	transform: scale(1.1);
}

.doctor-details-image {
	object-fit: cover;
}

@media screen and (max-width: 1400px) {
	.card-wrapper {
		width: 20%;
	}
}

@media screen and (max-width: 1024px) {
	.card-wrapper {
		width: 30%;
	}
}

@media screen and (max-width: 700px) {
	.card-wrapper {
		width: 45%;
	}
}

@media screen and (max-width: 500px) {
	.card-wrapper {
		width: 60%;
	}
}

@media screen and (max-width: 350px) {
	.card-wrapper {
		width: 90%;
	}

	.card-wrapper img {
		width: 100px;
		height: 100px;
	}

	.visible-doctor-card h1 {
		font-size: 1rem;
		word-wrap: break-word;
		flex-wrap: wrap;
	}
}

@keyframes card-wrapper-appearance {
	0% {
		right: -100%;
	}

	100% {
		right: 0%;
	}
}
