.login-page-wrapper {
	background: url("/public/images/medical-background.png");
	background-color: rgba(0, 0, 0, 0.1);
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.login-content-wrapper {
	background-color: white;
	width: 60%;
	height: 60%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	border-radius: 10px;
	box-shadow: 5px 5px 5px rgb(82, 80, 80);
}

.login-form-wrapper {
	flex-basis: 50%;
}

.login-form-wrapper h3 {
	text-align: center;
	margin: 1rem 0;
}

.login-form-wrapper form {
	display: flex;
	flex-direction: column;
}

.login-form-wrapper form label {
	text-align: center;
	font-size: 1rem;
	margin: 1rem 0;
}

.form-input {
	border-radius: 5px;
	border-style: none;
	background-color: rgb(233, 233, 233);
	height: 2rem;
	margin-bottom: 1rem;
	text-align: center;
}

.login-button {
	margin: auto;
	width: 40%;
	border-style: none;
	background-color: var(--global-color-primary);
	border-radius: 5px;
	padding: 0.5rem 0.5rem;
	color: white;
}

@media screen and (max-width: 1200px) {
	.login-content-wrapper {
		flex-direction: column;
	}
}
