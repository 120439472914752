.admin-links-wrapper table td {
    padding: 1rem;
    text-align: center;
    vertical-align: middle;
}

.link-row {
    flex: 1 1 0;

}

.links-button {
	border: none;
	color: white;
	padding: 10px 20px;
	border-radius: 15px;
	font-weight: 700;
	margin: 10px;
}

.links-button:hover {
	transform: scale(1.1);
}

.links-edit {
	background-color: rgb(255, 136, 0);
}

.links-delete {
	background-color: red;
}

.link-row {
	border-bottom: 1px solid lightgray;
	vertical-align: middle;
}

.link-logo {
	max-width: 100px;
}

.link-arrows-td {
	display: flex;
	flex-direction: column;
	gap: 5px;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	height: 100%;
}

.link-arrows-td * {
	color: var(--global-color-primary);
	cursor: pointer;
}

@media screen and (max-width: 1000px) {
	.links-wrapper table {
		min-width: unset;
		max-width: 98%;
	}
	.links-wrapper table td {
		padding: unset;
	}
}

@media screen and (max-width: 800px) {
	.links-wrapper {
		padding: unset;
	}

	.links-wrapper table {
		min-width: unset;
	}
}
