.users-wrapper {
	padding: 2rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.users-wrapper table {
	width: 100%;
	border: 2px solid var(--global-color-primary);
	min-height: 200px;
	min-width: 800px;
	padding: 1rem;
}

.users-wrapper table thead {
	padding: 1rem;
}

.users-wrapper table th {
	padding: 1rem;
	text-align: center;
	border-bottom: 1px solid black;
}

.users-wrapper table td {
	padding: 1rem;
	text-align: center;
}

.user-tab {
	display: flex;
	justify-content: center;
}

@media screen and (max-width: 1000px) {
	.users-wrapper table {
		min-width: unset;
		max-width: 98%;
	}

	.id-column {
		display: none;
	}
}

@media screen and (max-width: 800px) {
	.users-wrapper {
		padding: unset;
	}

	.users-wrapper table {
		min-width: unset;
	}
}
