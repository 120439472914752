.home-news-wrapper {
	margin-bottom: 2rem;
	overflow: hidden;
}

.home-news-wrapper h1 {
	color: var(--global-color-text-blue);
	font-size: 2.5rem;
	font-weight: 800;
	text-align: center;
	margin: 1rem 0;
	position: relative;
	animation: home-news-h1-appearance 1.5s ease;
}

.home-news-carousel {
	display: flex;
	justify-content: space-around;
	position: relative;
	/* animation: home-news-carousel-appearance 3s ease; */
}

.home-news-no-news {
	text-align: center;
	color: var(--global-color-primary);
	font-size: 1.5rem;
	margin: 2rem 0;
}

@keyframes home-news-h1-appearance {
	0% {
		left: -100%;
	}

	50% {
		left: 20%;
	}
	100% {
		left: 0%;
	}
}

@keyframes home-news-carousel-appearance {
	0% {
		bottom: -100px;
	}

	100% {
		bottom: -1px;
	}
}

@media screen and (max-width: 1024px) {
	.home-news-carousel {
		flex-direction: column;
		justify-content: center;
	}
}
