@import "https://use.fontawesome.com/releases/v6.1.1/css/all.css";

.hero-wrapper {
	display: flex;
	padding: 1rem;
	overflow: hidden;
}

.hero-img {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hero-circle {
	background-color: var(--global-color-background-secondary-green);
	height: 600px;
	width: 600px;
	border-radius: 50%;
	position: absolute;
	z-index: -10;
	overflow: hidden;
	animation: hero-circle-size 3s linear infinite;
}

.heart{
   width: 100px;
   height: 100px;
   transform: rotate(45deg);
   transform-origin: center;
   position: relative;
   background: red;
   margin: 100px auto;
}
.heart::before, .heart::after{
   content: '';
   position: absolute;
   width: 100px;
   height: 100px;
   border-radius: 50%;
   background: red;
}
.heart::before{
   left: -50px;
}

.hero-img img {
	/* width: 50%; */
	max-width: 480px;
	animation: hero-appearance 3s ease-in;
}

@keyframes hero-appearance {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes hero-circle-size {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}

.hero-info {
	width: 50%;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2rem;
}

.hero-info i {
	color: red;
}

.hero-info h2 {
	color: var(--global-color-background-secondary-green);
	font-size: 1.5rem;
}

.hero-info h1 {
	color: var(--global-color-text-blue);
	font-size: 2.5em;
	font-weight: 800;
}

.hero-info p {
	color: var(--global-color-text-blue);
	font-size: 1.3rem;
	letter-spacing: 2px;
}

.hero-btn {
	background-color: var(--global-color-primary);
	width: 200px;
	padding: 20px 30px;
	text-align: center;
	text-decoration: none;
	border-radius: 30px;
	color: white;
	font-weight: 700;
	border: 2px solid var(--global-color-primary);
}

.hero-btn:hover {
	background-color: white;
	color: var(--global-color-primary);
}

@media screen and (max-width: 1400px) {
	.hero-info h1 {
		font-size: 3rem;
	}

	.hero-circle {
		height: 450px;
		width: 450px;
	}

	.hero-img img {
		width: 50%;
	}
}

@media screen and (max-width: 1200px) {
	.hero-info h1 {
		font-size: 2rem;
	}

	.hero-info p {
		font-size: 1rem;
	}

	.hero-circle {
		height: 400px;
		width: 400px;
	}
}

@media screen and (max-width: 1000px) {
	.hero-circle {
		height: 250px;
		width: 250px;
	}

	.hero-img img {
		width: 50%;
	}
}

@media screen and (max-width: 800px) {
	.hero-img {
		display: none;
	}
	.hero-wrapper {
		justify-content: center;
		text-align: center;
		width: 100%;
	}

	.hero-wrapper * {
		width: 100%;
	}

	.hero-btn {
		width: 200px;
	}

	.hero-info {
		align-items: center;
	}
}
