.regulations-page-wrapper {
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	background-repeat: repeat-y;
	max-width: 1600px;
	margin: 0 auto;
	overflow: hidden;
}

.regulations-page-heading {
	text-align: center;
	color: var(--global-color-text-blue);
	font-size: 2rem;
	font-weight: 700;
	position: relative;
	animation: regulations-page-heading-appearance 3s ease-in-out;
}

.regulations-page-single-regulation-wrapper {
	bottom: -100vh;
	position: relative;
	animation: regulations-page-single-regulation-appearance 1s forwards;
	box-shadow: 5px 5px 15px lightgray;
	padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.regulations-page-single-regulation-logo-title-wrapper {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.regulations-page-single-regulation-wrapper:hover h3 {
	text-decoration: underline;
}

.regulations-page-single-regulation-wrapper:hover {
	cursor: pointer;
}

.regulations-page-single-regulation-wrapper h3 {
	color: var(--global-color-text-blue);
	font-weight: 700;
	margin-bottom: 0.5rem;
}

.regulations-page-single-regulation-wrapper p {
	color: var(--global-color-primary);
}

.regulations-page-no-regulations {
	text-align: center;
	color: var(--global-color-primary);
	font-size: 1.5rem;
	margin: 2rem 0;
}

.home-single-regulation-date{
	position: absolute;
	top: -10px;
	right: 0;
	background-color: var(--global-color-background-secondary-green) ;
	padding: 5px;
	border-radius: 5px;
	color: white;
}

.regulation-img{
    max-width: 50px;
}

@keyframes regulations-page-single-regulation-appearance {
	0% {
		bottom: -100vh;
	}

	100% {
		bottom: 0vh;
	}
}

@keyframes regulations-page-heading-appearance {
	0% {
		right: -100%;
	}

	50% {
		right: 20%;
	}

	100% {
		right: 0%;
	}
}


@media screen and (max-width: 600px) {
	.regulations-page-single-regulation-wrapper {
		flex-direction: column;
		padding: 2rem;
		align-items: unset;
		gap: 1rem;
	}
}