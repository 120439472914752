.apply-page-wrapper {
	background: url("/public/images/apply-page3.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* align-items: center; */
}

.apply-page-content-wrapper {
	display: flex;
	gap: 2rem;
	flex-direction: column;
	padding: 2rem 4rem;
	width: 50%;
	margin: auto auto;
	background-color: white;
	border-radius: 10px;
	box-shadow: 1px 1px 2px lightgrey;
	animation: apply-page-wrapper-appearance 1.5s linear;

	position: relative;
}

.apply-page-header {
	color: var(--global-color-text-blue);
	text-align: center;
	font-size: 1.5rem;
	font-weight: 700;
}

.apply-page-form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
}

.apply-page-form-label {
	color: var(--global-color-primary);
	font-weight: 700;
}

.apply-page-form-input {
	border: none;
	width: 40%;
	border-bottom: 2px solid grey;
	transition: ease-in-out, width 0.35s ease-in-out;
}

.apply-page-form-input:focus {
	outline: none;
	width: 60%;
	border-bottom: 2px solid grey;
}

.apply-page-submit-button {
	background-color: var(--global-color-primary);
	border: 2px solid var(--global-color-primary);
	border-radius: 10px;
	padding: 10px 30px;
	color: white;
	font-weight: 700;
}

.apply-page-submit-button:hover {
	color: var(--global-color-primary);
	background-color: white;
}

@keyframes apply-page-wrapper-appearance {
	0% {
		left: -100%;
	}

	100% {
		left: 0%;
	}
}


@media screen and (max-width:1200px) {
    .apply-page-content-wrapper {
        width: 80%;
    }

    .apply-page-form-input {
        width: 50%;
    }

    .apply-page-form-input:focus {
	width: 70%;
    }
}

@media screen and (max-width:800px) {
    .apply-page-content-wrapper {
        width: 95%;
    }

    .apply-page-form-input {
        width: 60%;
    }

    .apply-page-form-input:focus {
	width: 80%;
    }
}