.not-found-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.not-found-text {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.not-found-heading{
    color: var(--global-color-text-blue);
    font-size: 2rem;
    font-weight: 700;
}

.not-found-button{
    background-color: var(--global-color-background-secondary-green);
    color: white;
    width: 200px;
    padding: 10px 30px;
    border-radius: 30px;
    text-decoration: none;
    font-weight: 700;
    text-align: center;
    border: 2px solid var(--global-color-background-secondary-green);
}

.not-found-button:hover {
    color: var(--global-color-background-secondary-green);
    background-color: white;
}

@media screen and (max-width: 1024px) {
    .not-found-wrapper{
        flex-direction: column-reverse;
        gap: 1rem;
        align-items: center;
    }

    .not-found-text {
        align-items: center;
    }

    .not-found-heading{
        text-align: center;
    }

    .not-found-image img {
        max-width: 400px;
    }
}