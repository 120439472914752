.contact-form-page-wrapper {
	display: flex;
	width: 80%;
	margin: auto;
	justify-content: space-around;
	margin-top: 5rem;
	margin-bottom: 5rem;
	color: var(--global-color-primary);
	overflow: hidden;
}

.contact-form-wrapper {
	flex-basis: 50%;
	position: relative;
	animation: contact-form-wrapper-appearance 1s linear;
}

@keyframes contact-form-wrapper-appearance {
	0% {
		right: -100%;
	}

	100% {
		right: 0%;
	}
}

.contact-form-wrapper i {
	color: rgb(122, 122, 122);
}

.contact-form {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.contact-form-wrapper h3 {
	text-align: center;
}

.contact-form-wrapper form {
	display: flex;
	flex-direction: column;
}

.contact-form-wrapper form label {
	text-align: center;
	font-size: 1rem;
}

.contact-form-wrapper form input {
	border-radius: 5px;
	border-style: none;
	background-color: rgb(233, 233, 233);
	height: 2rem;
	margin-bottom: 1rem;
	text-align: center;
}

.contact-form-wrapper form textarea {
	background-color: rgb(233, 233, 233);
	border-radius: 5px;
	border-style: none;
}

.contact-form-submit-button {
	display: flex;
	justify-content: center;
	margin: auto;
	width: 40%;
	border-style: none;
	background-color: var(--global-color-primary) !important;
	border-radius: 5px;
	color: white;
	margin-top: 1rem;
	font-weight: 700;
	border: 2px solid var(--global-color-primary);
}

.contact-form-submit-button:hover {
	background-color: white !important;
	color: var(--global-color-primary);
	border: 2px solid var(--global-color-primary);
}

.contact-form-alert {
	text-align: center;
}

.contact-from-textarea {
	margin-bottom: 1rem;
}

.contact-info-wrapper {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	position: relative;
	animation: contact-info-wrapper-appearance 1s linear;
}

@keyframes contact-info-wrapper-appearance {
	0% {
		left: -100%;
	}

	100% {
		left: 0%;
	}
}

.contact-info-wrapper h2 {
	font-size: 1.5rem;
}

.contact-form-wrapper h2 {
	font-size: 1.5rem;
}

@media screen and (max-width: 600px) {
	.contact-form-page-wrapper {
		flex-direction: column;
		gap: 2rem;
	}

	.contact-form-wrapper h2{
		text-align: center;
	}
}