.footer-wrapper {
	background-color: var(--global-color-footer-background);
	color: var(--global-color-primary);
	display: flex;
	flex-direction:  column;
	align-items: center;
	justify-content: center;
	gap: 10%;
	padding: 2rem;
	margin: 0 auto;
}

.footer-info-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10%;
	padding: 2rem;
	margin: 0 auto 1rem auto;
	width: 100%;

}

.footer-info {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.designer a {
	display: flex;
	align-items: center;
	gap: 10px;
	text-decoration: none;
	color: var(--global-color-primary);
}