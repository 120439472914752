.usefulLinks-page-wrapper {
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	background-repeat: repeat-y;
	max-width: 1600px;
	margin: 0 auto;
	overflow: hidden;
}

.usefulLinks-page-heading {
	text-align: center;
	color: var(--global-color-text-blue);
	font-size: 2rem;
	font-weight: 700;
	position: relative;
	animation: usefulLinks-page-heading-appearance 3s ease-in-out;
}

.usefulLinks-page-single-new-wrapper {
	bottom: -100vh;
	position: relative;
	animation: usefulLinks-page-single-new-appearance 1s forwards;
	box-shadow: 5px 5px 15px lightgray;
	padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 130px;
    padding: 0 2rem ;
}

.usefulLinks-page-single-new-wrapper:hover h3 {
	text-decoration: underline;
}

.usefulLinks-page-single-new-wrapper:hover {
	cursor: pointer;
}

.usefulLinks-page-single-new-wrapper h3 {
	color: var(--global-color-text-blue);
	font-weight: 700;
	margin-bottom: 0.5rem;
}

.usefulLinks-page-single-new-wrapper p {
	color: var(--global-color-primary);
}

.usefulLinks-page-no-usefulLinks {
	text-align: center;
	color: var(--global-color-primary);
	font-size: 1.5rem;
	margin: 2rem 0;
}

.home-single-new-date{
	position: absolute;
	top: -10px;
	right: 0;
	background-color: var(--global-color-background-secondary-green) ;
	padding: 5px;
	border-radius: 5px;
	color: white;
}

.usefulLinks-page-single-link-logo{
    max-width: 100px;
}


@keyframes usefulLinks-page-single-new-appearance {
	0% {
		bottom: -100vh;
	}

	100% {
		bottom: 0vh;
	}
}

@keyframes usefulLinks-page-heading-appearance {
	0% {
		right: -100%;
	}

	50% {
		right: 20%;
	}

	100% {
		right: 0%;
	}
}

@media screen and (max-width: 600px) {
	.usefulLinks-page-single-new-wrapper {
		flex-direction: column;
		gap: 1rem;
		height: unset;
		padding: 1rem;
	}
}