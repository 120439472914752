.practices-page-wrapper {
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	background-repeat: repeat-y;
	max-width: 1600px;
	margin: 0 auto;
	overflow: hidden;
}

.practices-page-heading {
	text-align: center;
	color: var(--global-color-text-blue);
	font-size: 2rem;
	font-weight: 700;
	position: relative;
	animation: practices-page-heading-appearance 3s ease-in-out;
}

.practices-page-single-practice-wrapper {
	bottom: -100vh;
	position: relative;
	animation: practices-page-single-practice-appearance 1s forwards;
	box-shadow: 5px 5px 15px lightgray;
	padding: 1rem;
	flex: 1 1 0px;
	max-width: 400px;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: space-between;
}

.practices-page-single-practice-wrapper:hover h3 {
	text-decoration: underline;
}

.practices-page-single-practice-wrapper:hover {
	cursor: pointer;
}

.practices-page-single-practice-wrapper h3 {
	color: var(--global-color-text-blue);
	font-weight: 700;
	margin-bottom: 0.5rem;
}

.practices-page-single-practice-wrapper p {
	color: var(--global-color-primary);
}

.practices-page-no-practices {
	text-align: center;
	color: var(--global-color-primary);
	font-size: 1.5rem;
	margin: 2rem 0;
}

.practices-wrapper {
	display: flex;
	justify-content: center;
	gap: 2rem;
	flex-wrap: wrap;
}

.practices-page-single-practice-part {
	-ms-word-break: break-all;
	word-break: break-all;

	/* Non standard for webkit */
	word-break: break-word;

	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;

	text-align: left;
	/* line-height: 1.5; */
	white-space: pre-line;
	max-width: 600px;
	min-width: 300px;
}

@keyframes practices-page-single-practice-appearance {
	0% {
		bottom: -100vh;
	}

	100% {
		bottom: 0vh;
	}
}

@keyframes practices-page-heading-appearance {
	0% {
		right: -100%;
	}

	50% {
		right: 20%;
	}

	100% {
		right: 0%;
	}
}
