.doctors-wrapper {
	padding: 2rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.doctors-wrapper table {
	width: 100%;
	border: 2px solid var(--global-color-primary);
	min-height: 200px;
	min-width: 800px;
	padding: 1rem;
}

.doctors-wrapper table thead {
	padding: 1rem;
}

.doctors-wrapper table th {
	padding: 1rem;
	text-align: center;
	border-bottom: 1px solid black;
}

.doctors-wrapper table td {
	padding: 1rem;
	text-align: center;
}

.doc-list-no-docs {
	text-align: center;
	color: var(--global-color-primary);
	font-size: 1.5rem;
	margin: 2rem 0;
}

.doc-tab {
	display: flex;
	justify-content: center;
}

.new-member-wrapper {
	margin: 1rem 0;
	display: flex;
}

.new-member-input {
	height: 100%;
	border-radius: 5px;
	border: 1px solid var(--global-color-primary);
}

.new-member-button {
	border: none;
	background-color: var(--global-color-primary);
	padding: 10px 30px;
	color: white;
	border-radius: 5px;
}

@media screen and (max-width: 1000px) {
	.doctors-wrapper table {
		min-width: unset;
		max-width: 98%;
	}

	.hidden {
		display: none;
	}

	.doctors-wrapper {
		padding: unset;
	}

	.doctors-wrapper table td {
		padding: unset;
	}
}

@media screen and (max-width: 800px) {
	.doctors-wrapper {
		padding: unset;
	}

	.doctors-wrapper table {
		min-width: unset;
	}

	.doctors-wrapper table {
		/* display: flex; */
		min-width: unset;
		justify-content: center;
		align-items: center;
	}
	
	.doctors-wrapper table thead {
		display: none;
	}

	.doctors-wrapper table tbody{
		overflow-x: auto;
		overflow-y: hidden;

		height: calc((counter(totalheader) * var(--responsive-table-cell-height)) + 0px);
	}

	.doctors-wrapper th,
	.doctors-wrapper td {
		/* header on left-side, so important to set same height for all cells */
		display: block;
		height: var(--responsive-table-cell-height);
		margin: 1rem auto;
	}
}

@media screen and (max-width: 600px) {
	.doc-tab {
		/* max-width: 95%; */
		overflow: hidden;
	}
	.doctors-wrapper table {
		min-width: unset;
	}

	.admin-doctors-tabs {
		display: flex;
		flex-direction: column;
	}
}

