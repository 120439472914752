.doctor-button {
	border: none;
	color: white;
	padding: 10px 20px;
	border-radius: 15px;
	font-weight: 700;
	margin: 10px;
}

.doctor-button:hover {
	transform: scale(1.1);
}

.doctor-edit {
	background-color: rgb(255, 136, 0);
}

.doctor-delete {
	background-color: red;
}

.doctor-row {
	border-bottom: 1px solid lightgray;
}

@media screen and (max-width: 1000px) {
	.doctors-wrapper table {
		min-width: unset;
		max-width: 98%;
	}

	.doc-description-row {
		display: none;
	}
	.doctors-wrapper table td {
		padding: unset;
	}
}

@media screen and (max-width: 800px) {
	.doctors-wrapper {
		padding: unset;
	}

	.doctors-wrapper table {
		min-width: unset;
	}
}
