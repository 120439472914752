.statute-page-wrapper {
    padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	background-repeat: repeat-y;
	max-width: 1600px;
	margin: 0 auto;
	overflow: hidden;
}

.statute-page-wrapper strong {
    font-weight: 700;
}