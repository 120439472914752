.thank-you-page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:  center;
    padding: 25vh;
    gap: 1rem;
}

.thank-you-page-wrapper h1 {
    font-size: 120px;
    color: var(--global-color-text-blue);
}

.thank-you-page-wrapper p {
    color: var(--global-color-primary);
}