.regulations-wrapper {
	padding: 2rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.regulations-wrapper table {
	width: 100%;
	border: 2px solid var(--global-color-primary);
	min-height: 200px;
	min-width: 800px;
	padding: 1rem;
}

.regulations-wrapper table thead {
	padding: 1rem;
}

.regulations-wrapper table th {
	padding: 1rem;
	text-align: center;
	border-bottom: 1px solid black;
}

.regulations-wrapper table td {
	padding: 1rem;
	text-align: center;
}

.regulations-list-no-regulations {
	text-align: center;
	color: var(--global-color-primary);
	font-size: 1.5rem;
	margin: 2rem 0;
}

.regulations-tab {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

@media screen and (max-width: 1000px) {
	.regulations-wrapper table {
		min-width: unset;
		max-width: 98%;
	}

	.hidden {
		display: none;
	}

	.regulations-wrapper {
		padding: unset;
	}

	.regulations-wrapper table td {
		padding: unset;
	}
}

@media screen and (max-width: 800px) {
	.regulations-wrapper {
		padding: unset;
	}

	.regulations-wrapper table {
		min-width: unset;
	}
}

@media screen and (max-width: 800px) {
	/* here we make the header positioned on the left-side */
	table.table-sticky-header {
		display: flex;
	}

	.table-sticky-header thead {
		display: none;
	}
	
	.table-sticky-header tbody {
		/* display: flex; */
		/* show horizontal scroller */
		overflow-x: auto;
		/* hide vertical scroller */
		overflow-y: hidden;
		/* important to set height
       total column 
=
 5 * each cell height (40px) 
=
 200px + (horizontal scrollbar's height)
       */
		height: calc((counter(totalheader) * var(--responsive-table-cell-height)) + 0px);
	}
	.table-sticky-header th,
	.table-sticky-header td {
		/* header on left-side, so important to set same height for all cells */
		display: block;
		height: var(--responsive-table-cell-height);
		margin: 1rem auto;
	}
}
