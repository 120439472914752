.new-member-label {
	color: var(--global-color-primary);
	margin-right: 1rem;
}

.new-member-button {
	border: 2px solid var(--global-color-primary);
	font-weight: 700;
}

.new-member-button:hover {
	background-color: white;
	color: var(--global-color-primary);
}

.memeber-delete-button {
	background-color: red;
	border: none;
	color: white;
	padding: 10px 20px;
	border-radius: 15px;
	font-weight: 700;
	margin: 10px;
}

.memeber-delete-button:hover {
	transform: scale(1.1);
}

.memeber-edit-button {
	background-color: rgb(255, 136, 0);
	border: none;
	color: white;
	padding: 10px 20px;
	border-radius: 15px;
	font-weight: 700;
	margin: 10px;
}

.memeber-edit-button:hover {
	transform: scale(1.1);
}

.member-list-no-members {
	text-align: center;
	color: var(--global-color-primary);
	font-size: 1.5rem;
	margin: 2rem 0;
}

.new-member-wrapper {
	display: flex;
	justify-content: space-between;
	height: 50px;
}

.new-member-find-by-uin {
	display: flex;
	gap: 1rem;
}

.new-member-wrapper-alert {
	height: 100%;
}

.members-tab {
	display: flex;
	justify-content: center;
}

.obligations-wrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
	cursor: pointer;
	position: relative;
}

.obligations-pay-button {
	position: absolute;
	left: -100px;
	background-color: var(--global-color-primary);
	border: none;
	color: white;
	padding: 10px 20px;
	border-radius: 15px;
	font-weight: 700;
	margin: 10px;
	transition: all 600ms ease-in;
	overflow: hidden;
	opacity: 0;
}

.obligations-wrapper:hover .obligations-pay-button {
	left: 0px;
	opacity: 1;
}

.excel-button {
	border: none;
	background-color: transparent;
	margin: 5px;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.excel-button-logo {
	width: 50px;
}

.excel-button-logo:hover{
	-webkit-transform: rotate(360deg);
	-moz-transform: rotate(360deg);
	-o-transform: rotate(360deg);
	transform: rotate(360deg);
	-webkit-transition-duration: 2s;
	-moz-transition-duration: 2s;
	-o-transition-duration: 2s;
	transition-duration: 2s;
}

@media screen and (max-width: 800px){
	.new-member-wrapper{
		flex-direction: column;
		gap: 1rem;
		align-items: center;
		margin-bottom: 4rem;
	}

	.new-member-button {
		max-width: 80%;
	}

	.obligations-wrapper {
		align-items: center;
	}

	.obligations-pay-button {
		position: unset;
		left: unset;
		opacity: 1;
		width: 170px;
	}
}