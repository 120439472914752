.check-page-wrapper {
	display: flex;
	flex-direction: column;
	padding: 10rem;
	align-items: center;
	position: relative;
	background-image: url(/public/images/background1.jpg);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center center;
    background-size:cover;
}

.check-page-content-wrapper {
	background-color: white;
	padding: 4rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 15px;
	box-shadow: 1px 1px 2px grey;
	/* animation: check-page-wrapper-appearance 1.5s linear; */
	position: relative;
}

.check-page-form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	position: relative;
}

.check-page-heading {
	color: var(--global-color-text-blue);
	font-size: 2rem;
	font-weight: 700;
	margin: 2rem 0;
}

.check-page-form {
	color: var(--global-color-primary);
}

.check-page-form label {
	text-align: center;
}

.check-page-form button {
	background-color: var(--global-color-primary);
	color: white;
	font-weight: 700;
	padding: 10px 30px;
	border-style: none;
	border-radius: 10px;
	border: 2px solid var(--global-color-primary);
}

.check-page-form button:hover {
	background-color: white;
	color: var(--global-color-primary);
}

@media screen and (max-width: 800px) {
	.check-page-wrapper * {
		text-align: center;
	}

}

@keyframes check-page-wrapper-appearance {
	0% {
		left: -100%;
	}

	100% {
		left: 0%;
	}
}