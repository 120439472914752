.news-button {
	border: none;
	color: white;
	padding: 10px 20px;
	border-radius: 15px;
	font-weight: 700;
	margin: 10px;
}

.news-button:hover {
	transform: scale(1.1);
}

.news-edit {
	background-color: rgb(255, 136, 0);
}

.news-delete {
	background-color: red;
}

.news-row {
	border-bottom: 1px solid lightgray;
}

@media screen and (max-width: 1000px) {
	.news-wrapper table {
		min-width: unset;
		max-width: 98%;
	}
	.news-wrapper table td {
		padding: unset;
	}
}

@media screen and (max-width: 800px) {
	.news-wrapper {
		padding: unset;
	}

	.news-wrapper table {
		min-width: unset;
	}
}
