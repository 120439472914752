@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap");
@import "https://use.fontawesome.com/releases/v6.1.1/css/all.css";


:root {
	--global-color-primary: #01a0fb;
	--global-color-background: rgb(247, 251, 254);
	--global-color-background-secondary-green: rgb(172, 213, 142);
	--global-color-text-blue: rgb(35, 65, 138);
	--global-color-footer-background: rgb(247, 247, 247);
}

/* RESET */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	font-family: "Lexend Deca", sans-serif;
	background-color: var(--global-color-background);
}

.site-content {
	/* max-width: 1600px; */
	margin: 0 auto;
}

/* VARIABLES */


