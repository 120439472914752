.header-wrapper {
	display: flex !important;
	justify-content: space-around;
	align-items: center;
	padding: 1rem;
	max-width: 1600px;
	margin: auto;
}

.header-nav ul {
	display: flex;
	gap: 15px;
	align-items: center;
}

.header-nav a {
	text-decoration: none;
	font-size: 20px;
	color: var(--global-color-primary);
}

.header-logo {
	display: flex;
	align-items: center;
}

.header-logo p {
	max-width: 185px;
	margin-left: 20px;
	color: rgb(128, 128, 128);
}

.header-logo img {
	/* color: var(--global-color-primary);
	font-size: 30px;
	letter-spacing: 4px;
	font-weight: 700;
	text-shadow: 1px 2px 3px black;
	transform: scaleY(2); */
	width: 6rem;
}

.right-logo p{
	text-align: right;
	margin-right: 20px;
}

#dropdown-item-button {
	background-color: transparent;
	border: none;
	color: var(--global-color-primary);
	font-size: 20px;
	padding: 0;
	margin: 0;
	border-style: none !important;
}

#dropdown-item-button:active {
	border: none;
	border-style: none !important;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: none !important;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
	box-shadow: unset;
	color: var(--global-color-primary);
}

.nav-button {
	/* border-radius: 3px solid transparent; */
	padding: 15px;
}

.nav-button:hover {
	border-bottom: 3px solid var(--global-color-background-secondary-green);
	color: var(--global-color-background-secondary-green);
}

.hidden-dropdown {
	display: none;
}

.drop-down-button {
	background-color: transparent !important;
	border-style: none !important;
	font-size: 35px;
	color: var(--global-color-primary);
}

.btn-primary:hover {
	color: var(--global-color-primary);
	background-color: #0b5ed7;
	border-color: #0a58ca;
}

.dropdown-item {
	color: var(--global-color-primary);
	margin: 15px 0;
	font-size: 24px;
}

.dropdown-item:hover {
	color: var(--global-color-background-secondary-green);
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
	color: var(--global-color-primary);
	background-color: #0a58ca;
	border-color: #0a53be;
}

.header-logo:hover {
	cursor: pointer;
}

@media screen and (max-width: 1700px) {
	header {
		width: 100%;
		margin: 0;
		justify-content: space-around;
	}

	.header-nav ul {
		/* display: flex; */
		gap: 5px;
		flex-wrap: wrap;
	}
}

@media screen and (max-width: 1520px) {
	.header-logo p {
		max-width: 185px;
		margin-left: 20px;
		color: rgb(128, 128, 128);
	}

	.header-logo img {
		width: 4rem;
	}

	.right-logo p {
		display: none;
	}
}

@media screen and (max-width: 1320px) {
	.header-nav {
		display: none;
	}

	.hidden-dropdown {
		display: block;
	}

	.right-logo p {
		display:unset;
	}
}

@media screen and (max-width: 500px) {
	.header-logo p {
		display: none;
	}
}
