.contacts-header-content {
	background-image: url(/public/images/Medical+Banner.jpg);
	background-position: center center;
	background-repeat: no-repeat;
	/* color: var(--global-color-primary); */
	color: white;
	text-shadow: 2px 2px 2px gray;
}

.contacts-header-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	padding: 6rem;
}

.contacts-header-wrapper h1 {
	font-size: 2rem;
	text-align: center;
}
