.create-doctor-page-wrapper {
	/* background: url("/public/images/medical-background.png"); */
	/* background-color: rgba(0, 0, 0, 0.1); */
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 2rem 1rem;
}

.create-doctor-content-wrapper {
	background-color: white;
	width: 60%;
	height: 60%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	border-radius: 10px;
	box-shadow: 1px 1px 20px rgb(82, 80, 80);
	padding: 2rem;
}

.create-doctor-form-wrapper {
	flex-basis: 50%;
	text-align: center;
}

.create-doctor-form-wrapper h3 {
	text-align: center;
	margin: 1rem 0;
	font-size: 2rem;
	color: var(--global-color-text-blue);
}

.create-doctor-form-wrapper form {
	display: flex;
	flex-direction: column;
}

.create-doctor-form-wrapper form label {
	text-align: center;
	font-size: 1rem;
	margin: 1rem 0;
	font-size: 1.5rem;
	color: var(--global-color-primary);
}

.form-input {
	border-radius: 5px;
	border-style: none;
	background-color: rgb(233, 233, 233);
	height: 2rem;
	margin-bottom: 1rem;
	text-align: center;
}

.create-doctor-button {
	margin: auto;
	width: 60%;
	border-style: none;
	background-color: var(--global-color-primary);
	border-radius: 5px;
	padding: 0.5rem 0.5rem;
	color: white;
	font-weight: 700;
	border: 2px solid var(--global-color-primary);
}

.create-doctor-button:hover {
	color: var(--global-color-primary);
	background-color: white;
}

.create-doctor-textarea {
	background-color: rgb(233, 233, 233);
	border: none;
	margin-bottom: 1rem;
}

.btn-file {
	position: relative;
	background-color: var(--global-color-primary);
	font-weight: 700;
	overflow: hidden;
	border: 2px solid var(--global-color-primary);
}

.btn-file:hover {
	background-color: white;
	color: var(--global-color-primary);
	border: 2px solid var(--global-color-primary);
}

.btn-file input[type="file"] {
	position: absolute;
	top: 0;
	right: 0;
	min-width: 100%;
	min-height: 100%;
	opacity: 0;
	outline: none;
	background: white;
	cursor: inherit;
	display: block;
}

@media screen and (max-width: 1200px) {
	.create-doctor-page-wrapper {
		flex-direction: column;
	}

	.create-doctor-content-wrapper {
		width: 100%;
	}
}

